import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import HomebtnFR from "../components/home.fr";


export default function Contact() {
  const query = graphql`
    {
      contentfulContactDescription(node_locale: { eq: "fr-CA" }) {
        contactDescription1
        contactDescription2
        title
      }
      allContentfulContact(
        filter: { node_locale: { eq: "fr-CA" } }
        sort: { fields: title, order: ASC }
      ) {
        nodes {
          id
          title
          icon
          link
        }
      }
    }
  `;
  const data = useStaticQuery(query);
  const contactList = data.allContentfulContact.nodes;
  const contactDescription = data.contentfulContactDescription;
  const contactItem = contactList.map((item) => {
    return (
      <ul className="tech-list" key={item.id}>
        <li>
          <a
            key={item.id}
            className="on-hover-40 text-purple"
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="mr-1">
              <i className={item.icon} />
            </span>
            {item.title}
          </a>
        </li>
      </ul>
    );
  });

  return (
    <div className="max-width">
      <div className="top-bottom-margin">
        <HomebtnFR></HomebtnFR>

        <div className="hide-text-block">
          <h3 className="text-white animate">{contactDescription.title}</h3>
        </div>

        <div className="max-width-70 text-white animate delay02">
          <h4>Contact</h4>

          <p>{contactDescription.contactDescription1}</p>
          <p>{contactDescription.contactDescription2}</p>

          <div className="links">{contactItem}</div>
        </div>
      </div>
    </div>
  );
}
